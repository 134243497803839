.projects-page {
  background-color: #f5f5f5;
  padding: 20px;
  padding-bottom: 100px;
  margin-top: -100px;
}

.projects-page h2{
  margin-top: 80px;
  text-align: center;
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 10px;
}

.project-page-category-button {
  display: flex;
  justify-content: center; 
  align-items: center; 
  margin-bottom: 20px;
}

.project-page-category-button button{
  font-size: 18px;
  margin: 10px;
  background-color: transparent;
  border: none;
  transition: border-bottom 0.2s; 
}

.project-page-category-button button.active{
  border-bottom: 1px solid black;
  transform: scale(1.2);
}
  
.project-page-cards {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap to the next row */
  justify-content: center;
  gap: 20px;
  margin: 0 auto; /* Center the cards on larger screens */
}

.project-page-card {
  width: calc(30% - 10px); /* Calculate the width for three cards in a row with spacing */
  margin-bottom: 20px; /* Add spacing between rows */
  box-sizing: border-box; /* Include padding and border in the width calculation */
  padding: 20px; /* Adjust padding as needed */
  border: 1px solid #ddd; 

  border-radius: 25px;
  background-color: #f5f5f5;
  overflow: hidden;
  display: flex;
  flex-direction: column; /* Display children in a column */
  justify-content: space-between; 
  box-sizing: border-box;
  min-height: 400px;
  /* height: 100%; */
}


.project-page-card:hover {
  background-color: #000;
}

.project-page-card:hover h3 {
  color: #fff;
}

.project-page-card:hover .project-page-place {
  color:#fff;
}

.project-page-card:hover .project-page-type {
  color:#fff;
}

.project-page-card:hover .project-page-view-more-button {
  background-color: #fff;
}

.project-page-card:hover .project-page-view-more-button a {
  color: #000;
}
.project-page-card:hover .project-page-view-more-arrow {
  color: #000;
}

.project-page-card-content {
  flex: 1;
  display: flex;
}
.project-page-card-content a{
  text-decoration: none;
}

.project-page-card-content h3 {
  font-size: 32px;
  margin: 0;
  height: 75%;
  width: 100%;
  color: #000;
  /* font-family: 'Fahkwang', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  align-items: flex-start;
}

.project-page-details {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  background-color: transparent;
}

.project-page-place-type {
  display: flex;
}

.project-page-place,
.project-page-type {
  padding: 5px 10px;
  border-radius: 25px;
  background-color: transparent;
  color: #000;
}

.project-page-view-more-button {
  
  padding: 10px 5px;
  background-color: #000;
  border-radius: 25px;
  text-align: center;
  margin-top: 10px; /* Add spacing to move "View More" button to a new row */
  width: 40%;
  align-self: right;
}

.project-page-view-more-button a {
  color: #fff;
  text-decoration: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.project-page-view-more-arrow {
  font-size: 16px;
  color: #fff;
  background-color: transparent;
}

@media screen and (max-width: 767px) { /* Mobile devices */
  .projects-page {
    background-color: #f5f5f5;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 100px;

  }
  .projects-page h2{
    font-size: 42px;
  }

  .project-page-category-button button{
    font-size: 16px;
    margin: 5px;
  }

  .project-page-card-content {
    padding: 10px;
    padding-bottom: 40px;
  }

  .project-page-card-content h3 {
    font-size: 28px;
    width: 100%;
  }

  .project-page-details {
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
  }

  .project-page-card {
    width: 90%; /* Calculate the width for three cards in a row with spacing */
    margin-bottom: 20px; /* Add spacing between rows */
    box-sizing: border-box; /* Include padding and border in the width calculation */
    padding: 20px; /* Adjust padding as needed */
    border: 1px solid #000;
    border-radius: 25px;
    background-color: #f5f5f5;
    overflow: hidden;
    display: flex;
    flex-direction: column; /* Display children in a column */
    justify-content: space-between; 
    box-sizing: border-box;
    min-height: 350px;
  }
}