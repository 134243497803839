.project-details-page {
  background-color: #f5f5f5;
  margin-top: -100px;
}

.project-details-header {
  width: 100%;
  height: 100%;
  margin: 0;
  border: none;
  background-color: #f5f5f5;
  overflow: hidden;
  display: flex;
  background-size: cover; 
  background-position: center center; 
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}

.project-details-header-content {
  flex: 1;
  height: 100%;
  padding: 120px 50px;
}

.project-details-header-content h3 {
  font-size: 56px;
  margin: 0;
  width: 50%;
  color: #fff;
  margin-bottom: 15px;
  /* font-family: 'Fahkwang', sans-serif; */
  font-family: 'Montserrat', sans-serif;
}

.project-details-header-content p {
  font-size: 1rem;
  color: #fff;
  margin-top: 10px;
  width: 50%;
}

.project-details-header-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  gap: 20px;
  background-color: transparent;
  bottom: 0;
}

.project-details-header-row-content {
  display: flex;
  align-items: center;
}

.project-details-place,
.project-details-type {
  padding: 5px 10px;
  border-radius: 25px;
  background-color: transparent;
  /* border: 1px solid black;
  color: #000; */
  border: 1px solid #fff;
  color: #fff;
  margin-right: 10px;
  text-align: center;
}

.questions-section h2 {
  font-size:  16px;
}

.project-details-card {
  background-color: #f5f5f5;
  border-radius: 25px;
  padding: 50px;
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: -20px;
}

.project-details-card-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.project-details-card-left{
  width: 50%;
  /* font-family: 'Fahkwang', sans-serif; */
  font-family: 'Montserrat', sans-serif;

}

.project-details-card-left h3{
  /* font-family: 'Fahkwang', sans-serif; */
  font-family: 'Montserrat', sans-serif;

}
 
.project-details-card-right {
  width: 35%;
  background-color: rgb(220,220,220, 0.2);
  border-radius: 25px;
  padding: 50px;
}

.project-detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
}

.project-detail-data {
  font-weight: 400;
  color: #626262;
  flex-wrap: wrap;
  text-align: end;
}

.project-details-card-gallery {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) { /* Mobile devices */
  .project-details-header-content {
    padding: 120px 20px 80px;
  }

  .project-details-header-content h3 {
    font-size: 38px;
    width: 100%;
  }

  .project-details-header-content p {
    width: 100%;
  }

  .project-details-card {
    padding-left: 80px;
    padding-right: 80px;
  }

  .project-details-card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .project-details-card-left{
    width: 100%;
    padding: 50px;
    padding-top: 0;
  }

  .project-details-card-right {
    width: 100%;
    border-radius: 25px;
    padding: 40px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) { /* Tablet devices */
  .project-details-header-content {
    padding-bottom: 80px;
  }

  .project-details-header-content h3 {
    width: 80%;
  }

  .project-details-header-content p {
    width: 80%;
  }

  .project-details-card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .project-details-card-left{
    width: 90%;
    padding: 50px;
  }

  .project-details-card-right {
    width: 90%;
    border-radius: 25px;
    padding: 50px;
  }
}

.custom-project-details-page {
  background-color: #f5f5f5;
  margin-top: -100px;
}

.custom-project-details-card {
  background-color: #f5f5f5;
  border-radius: 25px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: -20px;
}

.custom-project-details-grid {
  padding: 20px;
  padding-bottom: 50px;
  background-color: rgb(220,220,220, 0.2);
  border-radius: 25px;
  margin: 50px;
}

.custom-project-details-header-row {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr; 
  align-items: center;
  padding: 10px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #ccc;
}

.custom-project-details-data-row {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr; 
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}
.custom-project-details-place,
.custom-project-details-type,
.custom-project-details-client {
  /* border: 2px solid red; */
  padding: 5px 10px;
  border-radius: 25px;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  margin-right: 10px;
  text-align: center;
}
.custom-project-details-data-row p{
  padding: 0 20px;
  white-space: pre-wrap;
}

.description-content {
  position: relative;
}

@media screen and (max-width: 767px) { /* Mobile devices */
  .custom-project-details-grid {
    padding: 20px;
    padding-bottom: 50px;
    background-color: rgb(220,220,220, 0.2);
    border-radius: 25px;
    margin: 30px;
  }

  .custom-project-details-data-row p{
    padding: 0px;
    flex-wrap: wrap;
  }

  .custom-project-details-card {
    background-color: #f5f5f5;
    border-radius: 25px;
    padding: 10px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: -20px;
  }

  .custom-project-details-header-row {
    display: none;
  }
  .custom-project-details-data-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    text-align: center;
  }
  .capital-content {
    margin: 0;
  }
}