.career-section {
    background-color: #000;
    margin-top: -100px;
}

.career-section-header {
    background-image: url('/src/images/career-1.jpg');
    background-size: cover; 
    background-position: center center; 
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    text-align: left;
    height: 60vh; 
    color: #ffffff;
    padding: 20px; 
    padding-top: 50px;
}

.career-section-header h2{
    font-size: 68px;
    margin: 0 auto;
    margin-left: 10px;
    margin-top: 15%;
}

.career-section-header p{
    font-size: 24px;
    margin: 0 auto;
    margin-left: 10px;
}

.benefits-section {
    background-color: #f5f5f5; 
    border-radius: 25px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    width: 100%; 
    padding-bottom: 50px;
    padding-top: 50px;
    margin-top: -20px;
}

.benefits-section h3 {
    text-align: center;
}

.benefits-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px;
}

.benefit-card {
    width: calc(30% - 20px); 
    margin-bottom: 20px;
    padding: 20px;
    border: 0.5px solid #ddd; 
    background-color: #f5f5f5;
    border-radius: 10px; 
}

.benefit-card h4 {
    font-size: 18px;
    margin-bottom: 10px;
}

.benefit-card p {
    font-size: 14px;
    color: #666;
    line-height: 25px;
}

.job-section {
    display: flex;
    margin-top: 20px;
    overflow: hidden;
    width: 100%;
}

.image-container {
    width: 40%;
    overflow: hidden;
    border-radius: 25px 0 0 25px;
    padding: 50px;
}

.image-container img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 25px;
}

.tabs-container {
    width: 60%;
    padding: 50px;
}

.tabs-container-header {
    color: black;
    padding-left: 10px;
    display: flex;
    align-items: left;
    text-align: left;
}

.tab {
    margin-bottom: 20px;
    background-color: transparent;
    padding: 10px 20px 10px 0;
    border-bottom: 1px solid black;
}

.tab-header {
    display: grid;
    grid-template-columns: 2fr 1fr 0.5fr; 
    grid-gap: 10px; 
    align-items: center; 
    color: black;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.expand-button {
    font-size: 16px;
    line-height: 1;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    color: #000;
}

.job-description {
    display: none;
    padding: 10px;
    background-color: transparent;
    color: black;
    border-radius: 0 0 10px 10px;
}

.job-title {
    font-size: 18px;
    font-weight: bold;
}

.job-location {
    background-color: #f0f0f0;
    border-radius: 25px;
    padding: 5px 10px;
    font-size: 12px;
    text-align: center;
}

.tab.expanded .job-description {
    display: block;
}


@media screen and (max-width: 767px) { /* Mobile devices */
    .career-section-header h2{
        margin-top: 30%;
    }
    .benefits-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }

    .benefit-card {
        width: 80%; 
    }

    .job-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .image-container {
        width: 90%;
        padding: 20px;
    }

    .image-container img {
        height: 500px;
        object-fit: cover;
    }

    .tabs-container {
        width: 90%;
        padding: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) { /* Tablet devices */
    .benefits-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
    }

    .benefit-card {
        width: 90%; 
    }

    .job-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .image-container {
        width: 90%;
        padding: 20px;
    }

    .image-container img {
        height: 500px;
        object-fit: cover;
    }

    .tabs-container {
        width: 90%;
        padding: 20px;
    }
}