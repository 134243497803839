.transparent-navbar {
  background-color: white;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  z-index: 100;

}

.navbar-left {
  background-color: transparent;
  margin-left: 20px;
  top: 0;
  left: 0;
  z-index: 101; 
  /* border: 2px solid red; */

}

.navbar-left img {
  width: 180px;
  height: auto;
}

.navbar-center {
  position: fixed;
  margin-left: 35%;
  z-index: 101; 
}

.navbar-center ul {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0;
  background-color: black;
  color: white;
  align-items: center;
  border-radius: 50px;
  padding: 5px 25px;
}

.navbar-center li {
  margin: 0 16px; 
}
  
.navbar-center a {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s;
}
  
.navbar-center li:hover {
  /* color: #00bfff; */
  transform: scale(1.2);
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  margin: 0;
  gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown .dropbtn:hover {
  /* color: #00bfff; */
  transform: scale(1.2);
}
  
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #000;
  margin-top: -10px;
  z-index: 1;
  margin-left: -5px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding-bottom: 10px;
}
  
.dropdown-content li {
  float: none;
  color: black;
  padding: 10px 5px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.navbar-right {
  display: flex;
  position: fixed;
  left: 90%;
}
  
.hamburger {
  display: none;
  font-size: 18px;
  color: #000;
  cursor: pointer;
}

/* Media query for tablet and mobile screens */
@media (max-width: 768px) {
  .navbar-left {
    margin-left: 10px;
  }

  .navbar-left img {
    width: 150px;
    height: auto;
  }

  .navbar-center {
    display: none; 
  }
    
  .navbar-center.open {
    display: block;
    position: fixed;
    width: 100%;
    margin-left: 0px;
    top: 0;
    left: 0;
  }

  .navbar-center.open ul{
    display: flex;
    flex-direction: column;
    border-radius: 0;
    padding: 50px;
    background-color: #000;
  }

  .navbar-center.open ul li{
    margin-bottom: 10px;
  }

  .navbar-center.open a{
    color: #fff;
  }

  .navbar-center.open a:hover {
    color: #00bfff;
  }

  .dropdown .dropbtn {
    font-size: 16px;
    outline: none;
    color: #fff;
    padding: 0;
    margin: 0; 
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #000;
    margin-top: 5px;
    margin-left: -30px;
    padding-bottom: 10px;
  }

  .dropdown-content li {
    float: none;
    color: #fff;
    padding: 10px;
    text-decoration: none;
    display: block;
    text-align: center;
  }
    
  .hamburger {
    display: flex; 
    position: relative;
    color: #000;
    z-index: 100;
  }
  .close-sign {
    background-color: #f5f5f5;
    padding: 0px;
    border-radius: 50px;
  }

  .navbar-right {
    background-color: #f5f5f5;
    border-radius: 50px;
    padding: 10px;
    z-index: 102;
    left: 85%;
    box-shadow: 1px 1px 1px 1px rgb(220,220,220, 0.8);
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) { /* Tablet devices */
  .navbar-center {
    position: fixed;
    margin-left: 27%;
    z-index: 101; 
    /* border: 2px solid red; */
  
  }
  
}