.footer {
  background-color: #161616;
  padding-top: 30px;
  padding-bottom: 30px;
}

.footer h1 {
  clear: both;
  display: flex;
  justify-content: center;
  justify-self: center;
  align-items: center;
  padding-bottom: 20px;
  color: #ffffff;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  color: #555555;
  border-top: 1px solid #555555;
  border-bottom: 1px solid #555555;
  width: 100%;
  position: relative;
}

.contact-form {
  width: 50%;
  padding: 50px;
  padding-top: 50px;
}

.contact-form h3 {
  color: #ffffff;
  font-size: 28px;
  text-align: left;
  font-family: 'Fahkwang', sans-serif;
}

.contact-form-row {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.contact-form-input {
  width: 45%; 
  background-color: transparent;
  padding: 15px;
  margin: 0;
  border: none;
  color: #fff;
  border-bottom: 1px solid #555555;
}

.contact-form-subject {
  background-color: transparent;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #555555;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.custom-dropdown {
  appearance: none; 
  background-color: transparent; 
  border: 1px solid #555555; 
  color: #ffffff; 
  padding: 10px; 
  border-radius: 5px; 
  cursor: pointer; 
  width: 90%; 
  margin-left: 20px;
}

.custom-dropdown::after {
  content: '\25BC'; 
  background-color: #fff;
  position: absolute;
  right: 10px; 
  transform: translateY(-50%);
  color: #ffffff; 
}

.custom-dropdown option:hover {
  background-color: #ffffff; 
  color: #000;
}

.contact-form-message {
  background-color: transparent;
  padding: 5px;
  border: none;
  border-bottom: 1px solid #555555;
  width: 98.5%;
  margin-top: 20px;
  color: #fff;
  resize: none;
}

.thank-you-modal {
  position: fixed;
  top: 0;
  left: 0;
  margin-left: 25%;
  margin-top: 15%;
  width: 50%;
  height: 200px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  border-radius: 10px;
}

.thank-you-message {
  padding: 50px;
  border-radius: 5px;
  text-align: center;
}

.thank-you-modal button {
  background: #161616;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
}

.submit-button {
  display: flex;
  align-items: center;
  float: right;
  margin-top: 20px;
  cursor: pointer;
}

.submit-button button {
  background-color: transparent;
  padding: 5px;
  border: none;
  color: #ffffff;
  cursor: pointer;

}

.submit-button::after {
  content: '\2192'; 
  margin-left: 5px;
  color: #ffffff;
}

.footer-responsive-box {
  display: flex;
  flex-direction: row;
}

.footer-general-info {
  width: 50%;
  padding: 20px;
  padding-top: 50px;
  border-left: 1px solid #555555;
  border-right: 1px solid #555555;
  text-align: left;
}
.footer-general-info-content {
  display: flex;
  flex-direction: column;
}

.footer-general-info h3 {
  color : #555555;
}

.footer-general-info p {
  margin: 0;
  color: #ffffff;
  line-height: 25px;
}

.footer-general-info a {
  margin-bottom: 5px;
  color: #ffffff;
  line-height: 25px;
  text-decoration: none;
}

.footer-general-info a:hover{
  color : #CC9544;
}

.footer-social-icons {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.footer-social-icon {
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  border: 0.5px solid #555555;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  transition: background-color 0.3s;
}
.footer-social-icon-active {
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  border: 0.5px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  transition: background-color 0.3s;
}

.footer-social-icon-logo {
  color: #555555;
  font-size: 28px;
}
.footer-social-icon-logo-active {
  color: #ffffff;
  font-size: 28px;
}

.footer-social-icon-logo:hover {
  transform: scale(1.1);
}
.footer-social-icon-logo-active:hover {
  transform: scale(1.1);
}

.footer-navigation {
  width: 50%;
  padding: 20px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-logo {
  margin-bottom: 10px;
  margin: 0; 
  padding: 0;
}

.footer-logo img {
  width: 180px; 
  height: auto;
}

.footer-nav-item { 
  color : #ffffff;
  margin: 5px 0;
  padding-left: 10px;
  cursor: pointer;
  font-size: 28px;
  font-family: 'Fahkwang', sans-serif;
  text-align: left;
}

.footer-nav-item a{ 
  text-decoration: none;
  color : #ffffff;

}
.footer-nav-item a:hover {
  color : #CC9544;
}

.footer-text {
  clear: both;
  color: #555555;
  font-size: 14px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.copyright {
  left: 10%;
  bottom: 0;
  margin-left: 10%;
}

.privacy-terms {
  right: 10%;
  margin-right: 10%;
  bottom: 0;
}

.privacy-terms a {
  text-decoration: none;
  color: #555555;
}
.privacy-terms a:hover {
  color: #CC9544;
}

@media screen and (max-width: 767px) { /* Mobile devices */
  .footer {
    overflow-x: hidden;
  }
  .footer h1 {
    text-align: center;
  }
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .contact-form{
    width: 80%;
    padding-top: 20px;
  }

  .footer-responsive-box {
    border-top: 1px solid #555555;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .footer-general-info {
    align-items: center;
    justify-content: center;
    border-left: none;
    border-right: none;
    width:80%;
    padding-top: 10px;
    text-align: center;
  }

  .footer-general-info a {
    text-align: center;
  }

  .footer-social-icons {
    display: flex;
    margin-top: 20px;
  }

  .footer-social-icon {
    width: 40px;
    height: 40px;
  }
  .footer-social-icon-active {
    width: 40px;
    height: 40px;
  }

  .footer-social-icon-logo {
    font-size: 24px;
  }
  .footer-social-icon-logo-active {
    font-size: 24px;
  }

  .footer-navigation {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
  }

  .footer-logo {
    display: none;
  }

  .footer-nav-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-nav-item {
    font-size: 20px;
    margin: 10px;
  }

  .footer-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .copyright {
    left: 0;
    margin-left: 0;
    font-size: 12px;
  }

  .privacy-terms {
    right: 0;
    margin-right: 0;
    font-size: 12px;
  }

  .thank-you-modal {
    margin-top: 50%;
    height: 300px;
  }

  .thank-you-message {
    padding: 20px;
  }

}

@media screen and (min-width: 768px) and (max-width: 1023px) { /* Tablet devices */
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .contact-form{
    width: 80%;
    padding-top: 20px;
  }
  
  .footer-responsive-box {
    border-top: 1px solid #555555;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .footer-general-info {
    border-left: none;
    padding: 50px;
  }
  .footer-general-info a {
    text-align: center;
  }

  .footer-navigation {
    padding: 50px;
  }

  .footer-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .copyright {
    left: 0;
    margin-left: 0;
  }
  
  .privacy-terms {
    right: 0;
    margin-right: 0;
  }
}