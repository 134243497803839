.home-section {
  background-color: #161616;
  margin-top: -100px;
}

/* LANDING SECTION */
.landing-section {
  background-image: url('/src/images/background-image.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  min-height: 100vh;
}

.landing-section-content {
  background-image: url('/src/images/background-image.png');
  background-size: cover; 
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
  color: #ffffff;
}

.landing-title,
.landing-section h1,
.landing-subtitle,
.landing-description {
  margin: 0;
  padding: 0; 
}

.landing-title {
  line-height: 1.2;
  color: #ffffff;
  font-size:32px;
  font-weight:300;
}

.landing-section h1 {
  font-size: 96px;
  color: #ffffff;
}

.landing-subtitle {
  font-size: 42px;
  margin-top: 10px;
  color: #ffffff;
}

.landing-description {
  margin-top: 20px;
  color: #ffffff;
  width: 60%;
  font-size: 28px;
  font-weight: 300;
}

  /* ABOUT US SECTION */
.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  padding-left: 100px;
  padding-right: 100px;
  background-color: #f5f5f5;
  text-align: center;
  min-height: 100vh;
  border-radius: 50px;
  margin-top: -50px;
  margin-bottom: 20px;
}

.about-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.about-subtitle {
  font-size: 18px;
  margin-bottom: 20px;
  color: #555;
}

.about-description {
  margin-bottom: 20px;
  width: 60%;
  line-height: 30px;
}

.about-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
}

.about-image {
  width: calc(33.33% - 20px);
  margin: 10px;
  border-radius: 10px;
}

.main-image {
  width: 100%; 
  max-width: 100%; 
  height: 450px; 
  margin-top: -20%; 
  margin-bottom: 20px; 
  border-radius: 10px;
}

.explore-more-button {
  padding: 10px 20px;
  gap: 10px;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.explore-more-button a {
  color: #757575;
  text-decoration: none;
}

.explore-more-arrow {
  color:  rgb(0,0,0, 0.5);;
  border: 1px solid rgb(0,0,0, 0.5);
  padding: 10px;
  border-radius: 50px;
}

/* NUMBER COUNTER */
.number-counter-wrapper {
  background-color: #161616;
  width: 60%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 10px;
  margin-left: 60%;
  margin-top: 100px;
  border-radius: 25px;
}

@media screen and (max-width: 767px) { /* Mobile devices */
  .about-section {
    padding: 50px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: -50px;
  }
    
  .about-description {
    margin-bottom: 20px;
    width: 80%;
  }
    
  .number-counter-wrapper {
    width: 90%;
    margin-left: 90%;
    margin-top: 230px;
    display: flex; 
    flex-direction: column;
    justify-content: center;
  }

  .main-image {
    width: 100%; 
    max-width: 100%; 
    height: 350px; 
    margin-top: -55%; 
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) { /* Tablet devices */
  .about-description {
    margin-bottom: 20px;
    width: 80%;
    line-height: 30px;
  }

  .number-counter-wrapper {
    width: 80%;
    margin-left: 80%;
    margin-top: 350px;
    display: flex; 
    flex-direction: column;
    justify-content: center;
  }

  .main-image {
    width: 100%; 
    max-width: 100%; 
    height: 350px; 
    margin-top: -60%; 
  }
}

/* PROJECT SECTION */
.project-section {
  text-align: center;
  padding: 30px 0;
  padding-left: 50px;
  padding-bottom: 100px;
  background-color: #f5f5f5;
  border-radius: 50px;
  overflow: hidden;
}

.project-section h2 {
  text-align: left;
  font-size: 36px;
  width: 30%;
  font-weight:300;
  line-height: 50px;
}

.project-section-subtitle {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
  margin-bottom: 30px;
}

.view-all-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  margin-top: 50px;
  margin-left: -50px;
}

.view-all-button {
  margin: 0 auto;
  background-color: #161616;
  color: #fff;
  border: none;
  padding: 10px 30px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  gap: 5px;
}

.view-all-button a {
  text-decoration: none;
  color: #fff;
}
  
.view-all-arrow {
  font-size: 16px;
  color: #ffffff;
  background-color: transparent;
}

.project-carousel {
  display: flex;
  position: relative;
  margin: 50px auto;
}
  
.carousel-wrapper {
  display: flex;
  transition: transform 0.3s ease-in-out;
  padding-left: 20px;
}
  
.project-card {
  text-align: left;
  flex: 0 0 33.333%;
  padding: 20px;
  box-sizing: border-box;
  transition: transform 0.5s;
  opacity: 0.3;
}
  
.project-card.active {
  opacity: 1;
  transform: scale(1.1); 
}

.project-card-description {
  padding: 20px;
}

.project-image {
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
  height: 220px;
}

.project-name {
  margin-top: 10px;
  font-size: 18px;
}
  
.project-type {
  font-size: 16px;
  color: #666;
}
  
.project-description {
  margin-top: 10px;
  font-size: 16px;
  color: #888;
}
  
.project-read-more {
  clear: both; 
  float: right;
  margin: 10px;
  background-color: transparent;
  border-radius: 50px;
  color: #000;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  right:10%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 0;
}

.project-read-more a{
  text-decoration: none;
  color: #000;
} 
  
.project-read-more:hover {
  transform: scale(1.1);
}

.project-card.active .project-read-more{
  background-color: transparent;
}

  
.prev-button,
.next-button {
  background-color: transparent;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
}

.prev-button {
  margin-right: 5px;
  color: #000;
}
  
.next-button {
  margin-left: 5px;
}

.prev-next-button {
  margin: 0 auto;
  color: #000;
  border: none;
  padding: 10px 30px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
}

.prev-next-arrow {
  font-size: 18px;
  color: #000;
  background-color: transparent;
  margin-left: 20px;
}

@media screen and (max-width: 767px) { /* Mobile devices */
  .project-section {
    padding-bottom: 50px;
  }
  .project-section h2 {
    width: 80%;
  }

  .carousel-wrapper {
    display: flex;
    transition: transform 0.3s ease-in-out;
  }
  
  .project-card {
    flex: 0 0 -100%;
    padding: 20px;
    box-sizing: border-box;
    transition: transform 0.5s;
  }

  .project-card-description {
    padding: 10px;
  }

  .view-all-wrapper {
    margin-top: 30px;
  }
  .project-name {
    margin-top: 10px;
    font-size: 14px;
  }
  .project-image {
    height: 120px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) { /* Tablet devices */
  .project-section h2 {
    width: 80%;
  }

  .carousel-wrapper {
    display: flex;
    transition: transform 0.3s ease-in-out;
    padding-left: 20px;
  }
  
  .project-card {
    text-align: left;
    flex: 0 0 350px; 
    margin: 0 25px;
    opacity: 0.3;
  }
}

/* CLIENT FEEDBACK */
.client-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding-top: 50px;
  background-color: #161616;
  text-align: center;
}

.client-section h2 {
  color: #ffffff;
  font-size: 48px;
  margin-bottom: 20px;
}

.client-tabs {
  display: flex;
  margin-bottom: 20px;
  font-size: 18px;
}

.client-tab {
  padding: 10px 20px;
  color: #555555;
  cursor: pointer;
  margin-right: 10px;
}

.client-tab.active {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
}

.client-feedback {
  color: #ffffff;
  width: 45%;
}

.client-feedback-text{
  line-height: 30px;
}

.client-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 20px;
  margin-bottom: 20px;
}
  
.client-name {
  color: #ffffff;
  margin-bottom: 10px;    
}

.client-title {
  color: #555555;
}

@media screen and (max-width: 767px) { /* Mobile devices */
  .client-feedback {
    width: 65%;
  }

  .client-tabs {
    display: flex;
    flex-direction: row;
    font-size: 12px;
  }
  
  .client-tab {
    padding: 10px 10px;
    margin-right: 0;
  }
}

/* NEWS UPDATE */
.news-update-section {
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: #f5f5f5;
  border-radius: 50px;
}

.news-update-section h2 {
  text-align: left;
  padding-left: 30px;
  font-size: 36px;
  width: 100%;
  font-weight:300;
  line-height: 50px;
}

.news-update-section-subtitle {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.news-update-section-subtitle p{
  width: 70%;
  line-height: 30px;
}

.news-update-read-more {
  clear: both; 
  float: right;
  margin-top: 10px;
  background-color: #161616;
  color: white;
  border-radius: 50px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  right:10%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.news-update-read-more a{
  color: white;
  text-decoration: none;
}

.newsHomeCardWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px;
}

.newsHomeCardWrapper h2{
  width: 100%;
  margin-bottom: 5px;
  text-align: left;
  margin: 0;
  padding: 0;
}
  
.newsHomeCard {
  background-color: transparent;
  color: black;
  margin: 10px;
  width: calc(25% - 20px);
  border-top: 2px solid #555;
  display: flex;
  flex-direction: column;
}

.newsHomeCardType {
  text-align: left;
  font-size: 24px;
  font-weight: 500;
  color: #555
}

.newsHomeCardContent {
  font-size: 16px;
}

.newsHomeCardImage {
  height: 400px;
  width: 100%;
  border-radius: 25px;
  object-fit: cover;
}

@media screen and (max-width: 767px) { /* Mobile devices */
  .news-update-section-subtitle {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .news-update-section-subtitle p{
    width: 100%;
  }
    
  .newsHomeCardWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .newsHomeCard {
    margin: 0;
    padding-bottom: 50px;
    width: 100%;
    border-top: 2px solid #555;
    display: flex;
    flex-direction: column;
  }
  
  .news-update-section h2 {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) { /* Tablet devices */
  .newsHomeCardWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .newsHomeCard {
    margin: 0;
    padding-bottom: 50px;
    width: 100%;
    border-top: 2px solid #555;
    display: flex;
    flex-direction: column;
  }
}