.project-image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: transparent;
  padding: 10px;
  border-radius: 25px;
}
  
.project-image-container {
  cursor: pointer;
  width: 280px;
  height: 250px;
  padding: 0;
  margin: 10px;
}
  
.project-image-container img {
  width: 280px;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
}
  
.project-full-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
  
.project-full-image-container img {
  justify-content: center;
  align-items: center;
  align-self: center;
  max-width: 100%;
  max-height: 100%;
  border-radius: 15px;
}
  
@media screen and (min-width: 768px) and (max-width: 1023px) { /* Tablet devices */
  .project-full-image-container img {
    max-width: 80%;
    max-height: 80%;
    margin-left: 10%;
  }
}