.about-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    padding-left: 100px;
    padding-right: 100px;
    background-color: #f5f5f5;
    text-align: center;
    min-height: 100vh;
    border-radius: 50px;
    margin-top: -100px;
    padding-top: 100px;
  }
  
  .about-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .about-subtitle {
    font-size: 18px;
    margin-bottom: 20px;
    color: #555;
  }
  
  .about-description {
    margin-bottom: 20px;
    width: 60%;
    line-height: 30px;
  }
  
  .about-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
  }
  
  .about-image {
    width: calc(33.33% - 20px);
    margin: 10px;
    border-radius: 10px;
  }
  
  .main-image {
    width: 100%; 
    max-width: 100%; 
    height: 450px; 
    margin-top: -20%; 
    margin-bottom: 20px; 
    border-radius: 10px;
  }
  
  /* NUMBER COUNTER */
  .number-counter-wrapper {
    background-color: #161616;
    width: 60%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 10px;
    margin-left: 60%;
    margin-top: 100px;
    border-radius: 25px;
  }
  
  @media screen and (max-width: 767px) { /* Mobile devices */
    .about-section {
      padding: 50px;
      padding-top: 100px;
      padding-left: 30px;
      padding-right: 30px;
    }
      
    .about-description {
      margin-bottom: 20px;
      width: 80%;
    }
      
    .number-counter-wrapper {
      width: 90%;
      margin-left: 90%;
      margin-top: 230px;
      display: flex; 
      flex-direction: column;
      justify-content: center;
    }
  
    .main-image {
      width: 100%; 
      max-width: 100%; 
      height: 350px; 
      margin-top: -55%; 
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1023px) { /* Tablet devices */
    .about-description {
      margin-bottom: 20px;
      width: 80%;
      line-height: 30px;
    }
  
    .number-counter-wrapper {
      width: 80%;
      margin-left: 80%;
      margin-top: 350px;
      display: flex; 
      flex-direction: column;
      justify-content: center;
    }
  
    .main-image {
      width: 100%; 
      max-width: 100%; 
      height: 350px; 
      margin-top: -60%; 
    }
  }
  
  /* OUR PROCESS SECTION*/
  .process-section {
    padding: 50px;
    background-color: #161616;
  }
  .about-us-title {
    text-align: left;
    font-size: 38px;
    font-weight: bold;
    color: #000;
  }
    
  .about-us-description {
    margin-top: 10px;
    text-align: left;
    color: #000;
    padding-left: 20px;
    border-left: 1px solid red;
    width: 100%;
    line-height: 30px;
  }
  .process-title {
    text-align: left;
    font-size: 38px;
    font-weight: bold;
    color: #ffffff;
  }
    
  .process-description {
    margin-top: 10px;
    text-align: left;
    color: #ffffff;
    padding-left: 20px;
    border-left: 1px solid red;
    width: 60%;
    line-height: 30px;
  }
    
  .expandable-tab {
    margin-top: 0;
    border-bottom: 1px solid #ccc;
    overflow: hidden;
  }
    
  .expandable-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    cursor: pointer;
    background-color: transparent;
    color: #ffffff;
  }
    
  .expandable-title {
    font-size: 18px;
    font-weight: bold;
  }
    
  .arrow-icon {
    width: 16px;
    height: 16px;
    color: #ffffff;
    background-color: transparent;
    transform: rotate(45deg);
    transition: transform 0.3s;
  }
  
  .arrow-icon.up {
    transform: rotate(225deg);
  }
  
  .expand-button {
    background-color: transparent;
    border: none;
    font-size: 18px;
  }
  
  .expandable-content {
    display: none;
    padding: 10px 20px;
  }
    
  .expandable-image {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
    object-fit: cover;
    border-radius: 5px;
  }
    
  .expandable-short-description {
    width: 60%;
    line-height: 25px;
    margin: 0;
  }
    
  .expandable-description {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
  }
  
  .expandable-tab[data-expanded="true"] .expandable-content {
    display: block;
  }
  
  @media (max-width: 768px) {
    .process-description {
      width: 80%;
    }
    .about-us-description {
        width: 90%;
      }
  
    .expandable-short-description {
      width: 100%;
    }
  
    .expandable-image {
      max-width: 100%;
      height: 120px;
    }
  }