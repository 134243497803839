.news-section {
    display: flex;
    flex-direction: column;
    padding: 50px;
    padding-top: 100px; 
    padding-bottom: 100px;
    background-color: #f5f5f5;
    margin-top: -100px;
}

.news-section-content h1{
    padding-left: 30px;
    font-size: 36px;
    width: 50%;
    font-weight:300;
    line-height: 50px;
    font-family: 'Fahkwang', sans-serif;
}

.newsCardWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.newsCardTitle{
    width: 100%;
    margin-bottom: 5px;
    text-align: left;
    margin: 0;
    padding: 0;
}
  
.newsCard {
    background-color: transparent;
    color: black;
    border: none;
    margin: 20px;
    width: 30%;
    display: flex;
    flex-direction: column;
}

.newsCardContent {
    font-size: 16px;
}

.newsCardImage {
    height: 500px;
    width: 100%;
    border-radius: 25px;
    object-fit: cover;
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 767px) { /* Mobile devices */
    .news-section {
        justify-content: center;
        align-items: center;
    }
    
    .news-section-content h1{
        width: 100%;
        padding-top: 50px;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .newsCardWrapper {
        flex-direction: column;
        padding: 0;
    }

    .newsCard {
        width: 90%;
    }

    .newsCardTitle{
        margin: 0;
        margin-top: 10px;
        padding: 10px;
    }
    .newsCardContent {
        margin: 0;
        padding: 10px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) { /* Tablet devices */
    .news-section {
        justify-content: center;
        align-items: center;
    }
    
    .news-section-content h1{
        width: 100%;
        padding-top: 50px;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .newsCardWrapper {
        flex-direction: column;
        padding: 0;
    }

    .newsCard {
        width: 90%;
    }

    .newsCardTitle{
        margin: 0;
        margin-top: 10px;
        padding: 10px;
    }
    .newsCardContent {
        margin: 0;
        padding: 10px;
    }
}