.container {
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    font-size: 16px;
    border-radius: 25px;
    background-color: transparent;
  }
  
  .number {
    font-size: 26px;
    color: #ffffff;
    display: grid;
    text-align: left;
    place-items: left;
    font-weight: 600;
    align-items: left; 

  }
  .text {
    font-size: 16px;
    color: #555555;
    text-align: left;
    font-weight: 400;
    flex-wrap: wrap;
}
  .icon {
    width: 32px;
    height: 32px;
  }
  
  @media screen and (max-width: 767px) { /* Mobile devices */
    .container {
        padding-left: 20px;
        gap: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .number {
      font-size: 20px;
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1023px) { /* Tablet devices */
    .number {
      font-size: 16px;
    }
  }
  